import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { fetchLearners } from 'actions/Learner/learnerActions';
import { updateTaskDetails } from 'actions/Task/taskActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Learner } from 'entities/Learner.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { TasksContext } from 'hooks/Task/useTasksCache';
import { useTasksContext } from 'hooks/Task/useTasksContext';
import { queryClient } from 'index';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

import styles from './AssignTaskModal.module.scss';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  tasks: Task[];
  onSuccess?: () => void;
}

export const AssignTaskModal: FC<Props> = ({
  isOpen,
  onCloseModal,
  tasks,
  onSuccess
}) => {
  const { data: organizationData } = useOrganizationBySlug();
  const { data: userData } = useLoadAuthUserData();
  const { activeFolder, onChangeActiveFolder } = useTasksContext();

  const [selectedLearner, setSelectedLearner] = useState<Learner | null>(null);

  const learnerOptions = {
    organizationIdEq: organizationData!.id,
    facilitatorIdsEq:
      userData?.role === UserRoles.Facilitator ? userData.id : undefined
  };

  const firstTaskOrganizatinId = tasks[0].organizationId!;

  const { data: learnersData, isLoading: islearnersLoading } = useQuery(
    queryKeys.filteredLearners(firstTaskOrganizatinId, learnerOptions),
    () =>
      fetchLearners({
        ...learnerOptions,
        sort: ['firstName:ASC']
      }),
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0,
      enabled: !!organizationData?.id
    }
  );

  const { isLoading, isError, error, mutateAsync } = useMutation<
    Task,
    ApiError,
    Task['id'],
    TasksContext
  >(
    (taskId: Task['id']) =>
      updateTaskDetails(taskId, {
        assignedToId: selectedLearner?.id || null,
        parentId: null
      }),
    {
      onSuccess: async (updatedTask) => {
        if (activeFolder?.id === updatedTask.id) {
          onChangeActiveFolder(updatedTask);
        }

        await queryClient.setQueryData(
          queryKeys.taskDetails(updatedTask.id),
          updatedTask
        );
      }
    }
  );

  const handleAssignTasksList = () => {
    const requests = tasks.map(({ id }) => mutateAsync(id));

    Promise.all(requests).then(async () => {
      await queryClient.invalidateQueries(queryKeys.tasksList);
      await queryClient.invalidateQueries(queryKeys.folders);

      if (onSuccess) {
        onSuccess();
      }

      onCloseModal();
    });
  };

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={error?.errorMessage || ErrorMessages.FailedGetRequest}
      />
      <Modal
        title="Assign task to learner"
        isOpen={isOpen}
        onCloseModal={onCloseModal}
      >
        <div className={styles.container}>
          <Autocomplete
            id="choose-learner"
            aria-label="Choose learner"
            options={learnersData || []}
            getOptionLabel={(option: Learner) =>
              `${option.firstName} ${option.lastName}`
            }
            renderInput={(params) => (
              <TextField {...params} label="Choose learner" />
            )}
            onChange={(_e, value) => {
              if (typeof value !== 'string') {
                setSelectedLearner(value);
              }
            }}
            freeSolo
            open
            className={styles.autocomplete}
            classes={{
              paper: styles.paper,
              listbox: styles.listbox
            }}
          />
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={!selectedLearner || isLoading}
                onClick={handleAssignTasksList}
                loading={islearnersLoading || isLoading}
              >
                Assign
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};
