import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { createLearner } from 'actions/Learner/learnerActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Learner } from 'entities/Learner.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { queryClient } from 'index';

import { CreateLearnerForm, FormData } from '../CreateLearnerForm';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
}

export const CreateLearnerModal: FC<Props> = ({ isOpen, onCloseModal }) => {
  const { data: userData } = useLoadAuthUserData();
  const { data: organizationData } = useOrganizationBySlug();
  const { slugId } = useParams<{ slugId: string }>();

  const { isLoading, isError, isSuccess, error, mutateAsync } = useMutation<
    Learner,
    ApiError,
    FormData
  >(
    (formData: FormData) =>
      createLearner({
        firstName: formData.firstName,
        lastName: formData.lastName,
        username: formData.username,
        password: formData.password,
        avatar: formData.image,
        organizationId: organizationData!.id,
        facilitatorId:
          userData?.role === UserRoles.Facilitator ? userData.id : undefined
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          queryKeys.learners(organizationData!.id)
        );
        await queryClient.invalidateQueries(
          queryKeys.organizationDetails(slugId)
        );

        onCloseModal();
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage="Account successfully created"
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <Modal
        isOpen={isOpen}
        title="Add new account"
        onCloseModal={onCloseModal}
      >
        <CreateLearnerForm
          isLoading={isLoading}
          onSubmit={mutateAsync}
          onCloseModal={onCloseModal}
        />
      </Modal>
    </>
  );
};
