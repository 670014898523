import React, { FC } from 'react';
import { Grid, Link } from '@mui/material';
import logo from 'assets/logo.svg';

import styles from './LoginPage.module.scss';

interface Props {
  title: string;
}

export const LoginPage: FC<Props> = ({ title, children }) => (
  <Grid container alignItems="stretch" className={styles['login-page']}>
    <Grid item xs={12} md={6} className={styles.banner}>
      <h2 className={styles.title}>Creating a lifetime of independence</h2>
      <p className={styles.subtitle}>
        For more information, please visit our&nbsp;
        <Link
          color="inherit"
          underline="always"
          href="https://centralreach.com/product-privacy-policy/"
        >
          privacy policy
        </Link>
        .
      </p>
    </Grid>
    <Grid item xs={12} md={6} className={styles.content}>
      <img src={logo} alt="avail logo" className={styles.logo} />
      <div>
        <h3 className={styles['login-title']}>{title}</h3>
        {children}
      </div>
      <div className={styles.info}>
        <h5>Need support to login?</h5>
        <p>
          Our development team is here to help you. To raise a support
          request&nbsp;
          <Link
            href="https://availsupport.com/contact-support/"
            underline="always"
            target="_blank"
          >
            click here
          </Link>
          .
          <br />
          Or call the avail team at: <b>+1-954-787-8090</b>
        </p>
      </div>
    </Grid>
  </Grid>
);
