import React, { FC, useCallback } from 'react';
import { useQuery } from 'react-query';
import { fetchTaskActivitiesGroup } from 'actions/Task/taskActions';
import { SubRows } from 'components/DataTable/Row';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { SimpleTable } from 'components/SimpleTable';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ActivityGroup } from 'entities/ActivityGroup.entity';
import { Task } from 'entities/Task.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION, MAX_PAGE_LIMIT } from 'utils/constants';

import { collapsableColumns, columns } from './columns';
import { DurationTabSubRows } from './DurationTabSubRows';

import styles from './DurationTab.module.scss';

interface Props {
  taskId: Task['id'];
  isRowExpanded?: boolean;
  hideEmptyState?: boolean;
}

export const DurationTab: FC<Props> = ({
  taskId,
  isRowExpanded,
  hideEmptyState
}) => {
  const { data, isLoading, isError } = useQuery(
    queryKeys.taskActivityGroup(taskId),
    async () => {
      const activityGroups: ActivityGroup[] = await fetchTaskActivitiesGroup({
        taskId,
        limit: MAX_PAGE_LIMIT,
        fromTime: new Date().toISOString()
      });

      return activityGroups.map((activityGroup) => ({
        id: `${activityGroup.month}-${activityGroup.year}`,
        ...activityGroup
      }));
    },
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0
    }
  );

  const renderSubRows = useCallback(
    ({ row }: SubRows) => (
      <DurationTabSubRows
        row={row}
        taskId={taskId}
        hideEmptyState={hideEmptyState}
      />
    ),
    [hideEmptyState, taskId]
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <div className={styles.container}>
        <LoadingOverlay loading={isLoading}>
          <If condition={!isLoading}>
            <SimpleTable
              isLoading={isLoading}
              data={data || []}
              isRowExpanded={isRowExpanded}
              renderSubRows={renderSubRows}
              columns={isRowExpanded ? columns : collapsableColumns}
              emptyMessage={EmptyStateMessages.Activities}
            />
          </If>
        </LoadingOverlay>
      </div>
    </>
  );
};
