import { ActivityGroup } from 'entities/ActivityGroup.entity';
import { Assessment } from 'entities/Assessment.entity';
import { ScheduleDTO } from 'entities/DTO/ScheduleDTO.entity';
import { StepDTO } from 'entities/DTO/StepDTO.entity';
import { TaskDTO } from 'entities/DTO/TaskDTO.entity';
import { Learner } from 'entities/Learner.entity';
import { Schedule } from 'entities/Schedule.entity';
import { Step } from 'entities/Step.entity';
import { Task } from 'entities/Task.entity';
import { TaskActivity } from 'entities/TaskActivity.entity';
import { TaskAssetGroup } from 'entities/TaskAssetGroup.entity';
import { User } from 'entities/User.entity';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { TaskType } from 'enums/TaskType.enum';
import * as queryString from 'query-string';

import { ApiBase } from '../ApiBase';

export type TasksQuery = {
  sort: string[];
  limit?: number;
  offset?: number;
  search?: string;
  parentIdEq?: null | string;
  organizationIdEq?: null | string;
  parentIdIsNull?: boolean;
  publishStatusEq?: TaskStatus;
  typeEq?: TaskType;
  idIn?: string[];
  parentIdIn?: string[];
  exclude?: string[];
  repertoireIdEq?: string;
  assignedToIdEq?: string | null;
  createdByIdEq?: string;
  withDeleted?: boolean;
  sharableEq?: boolean;
  assignedToIdIsNull?: boolean;
  organizationIdIsNull?: boolean;
};

export type ActivitiesGroupQuery = {
  fromTime: string;
  taskId?: Task['id'];
  limit?: number;
  offset?: number;
};

export type ActivitiesQuery = {
  endTimeGt?: string;
  endTimeLt?: string;
  taskIdEq: Task['id'];
  limit?: number;
  offset?: number;
  sort: string[];
};

export type SchedulesQuery = {
  fromTime: string;
  toTime: string;
  learnerId?: Learner['id'];
  timezone?: string;
};

export type AssessmentsQuery = {
  limit?: number;
  offset?: number;
  sort: string[];
  assessorIdEq?: string;
  evaluatedIdEq?: string;
  taskIdEq?: string;
};

export interface TaskDuplicateOptions {
  sharable?: boolean;
  organizationId?: string | null;
  parentId?: string | null;
  assignedToId?: string | null;
  publishStatus: TaskStatus;
}

export interface DuplicateTaskToOrganizations {
  sharable?: boolean;
  organizationIds: string[];
}

export interface TaskAssetHistoryGroup {
  taskId: string;
  timezone?: string;
  offset?: number;
  limit?: number;
}

class TaskApi extends ApiBase {
  fetchTasks(tasksQuery: TasksQuery) {
    const query = queryString.stringify(tasksQuery);

    return this.client.get<Task[]>(`/tasks?${query}`);
  }

  fetchTaskDetails(taskId: Task['id']) {
    return this.client.get<Task>(`/tasks/${taskId}`);
  }

  createTask(taskCreateDTO: TaskDTO) {
    return this.client.post<Task>('/tasks', TaskDTO.serialize(taskCreateDTO));
  }

  updateTaskDetails(taskId: Task['id'], taskUpdateDTO: Partial<TaskDTO>) {
    return this.client.patch<Task>(
      `/tasks/${taskId}`,
      TaskDTO.serialize(taskUpdateDTO)
    );
  }

  deleteTasks(taskIds: Array<Task['id']>) {
    const query = queryString.stringify({ idIn: taskIds });

    return this.client.delete<null>(`/tasks?${query}`);
  }

  fetchAssessments(assessmentsQuery: AssessmentsQuery) {
    const query = queryString.stringify(assessmentsQuery, {
      arrayFormat: 'comma'
    });

    return this.client.get<Assessment[]>(`/assessments?${query}`);
  }

  duplicateTask(taskId: Task['id'], taskOptions: TaskDuplicateOptions) {
    return this.client.patch<Task>(`/tasks/${taskId}/duplicate`, taskOptions);
  }

  duplicateTasks(taskIds: string[], taskOptions: TaskDuplicateOptions) {
    const query = queryString.stringify({ idIn: taskIds });

    return this.client.patch<Task[]>(`/tasks/duplicate?${query}`, taskOptions);
  }

  duplicateTaskToOrganizations(
    taskIds: string[],
    taskOptions: DuplicateTaskToOrganizations
  ) {
    const query = queryString.stringify({
      idIn: taskIds
    });

    return this.client.patch<null>(
      `/tasks/duplicate-to-organizations?${query}`,
      taskOptions
    );
  }

  fetchTaskStep(taskId: Task['id'], stepId: Step['id']) {
    return this.client.get<Step>(`/tasks/${taskId}/steps/${stepId}`);
  }

  fetchTaskSteps(taskId: Task['id']) {
    return this.client.get<Step[]>(`/tasks/${taskId}/steps`);
  }

  createTaskStep(taskId: Task['id'], taskStepCreateDTO: StepDTO) {
    return this.client.post<Step>(
      `/tasks/${taskId}/steps`,
      StepDTO.serialize(taskStepCreateDTO)
    );
  }

  updateTaskStep(
    taskId: Task['id'],
    stepId: Step['id'],
    taskStepUpdateDTO: Partial<StepDTO>
  ) {
    return this.client.patch<Step>(
      `/tasks/${taskId}/steps/${stepId}`,
      StepDTO.serialize(taskStepUpdateDTO)
    );
  }

  moveTaskStep(taskId: Task['id'], stepId: Step['id'], index: number) {
    return this.client.post<Step[]>(`/tasks/${taskId}/steps/${stepId}/move`, {
      index
    });
  }

  moveTask(taskId: Task['id'], index: number, parentId: string | null) {
    return this.client.post<Task>(`/tasks/${taskId}/move`, {
      index,
      parentId
    });
  }

  fetchTaskSchedules(schedulesQuery: SchedulesQuery) {
    const query = queryString.stringify(schedulesQuery, {
      arrayFormat: 'comma'
    });

    return this.client.get<Schedule[]>(
      `/task-schedules/for-time-period?${query}`
    );
  }

  createTaskSchedule(scheduleCreateDTO: ScheduleDTO) {
    return this.client.post<Schedule>('/task-schedules', scheduleCreateDTO);
  }

  updateTaskSchedule(
    scheduleId: Schedule['id'],
    scheduleUpdateDTO: Partial<ScheduleDTO>
  ) {
    return this.client.patch<Schedule>(
      `/task-schedules/${scheduleId}`,
      scheduleUpdateDTO
    );
  }

  deleteTaskSchedule(scheduleId: Schedule['id']) {
    return this.client.delete<null>(`/task-schedules/${scheduleId}`);
  }

  deleteRepeatedTaskSchedule(scheduleId: Schedule['id'], stoppedAt: Date) {
    return this.client.post<Schedule>(
      `/task-schedules/${scheduleId}/stop-repeating`,
      {
        stoppedAt
      }
    );
  }

  fetchTaskActivities(activitiesQuery: ActivitiesQuery) {
    const query = queryString.stringify(activitiesQuery, {
      arrayFormat: 'comma'
    });

    return this.client.get<TaskActivity[]>(`/task-activities?${query}`);
  }

  fetchTaskActivitiesGroup(activitiesQuery: ActivitiesGroupQuery) {
    const query = queryString.stringify(activitiesQuery, {
      arrayFormat: 'comma'
    });

    return this.client.get<ActivityGroup[]>(`/task-activities/group?${query}`);
  }

  fetchTaskCount(tasksQuery: Omit<TasksQuery, 'sort'>) {
    const query = queryString.stringify(tasksQuery);

    return this.client.get<number>(`/tasks/count?${query}`);
  }

  fetchTaskAssetHistoryGroup(tasksQuery: TaskAssetHistoryGroup) {
    const query = queryString.stringify(tasksQuery);

    return this.client.get<TaskAssetGroup[]>(
      `/task-asset-history/group-by-date?${query}`
    );
  }

  fetchUserTasksForReport(learnerId: User['id']) {
    return this.client.get<Task[]>(`/tasks/user-sorted/${learnerId}`);
  }
}

const instance = new TaskApi('');

export { instance as TaskApi };
