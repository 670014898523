import { Learner } from 'entities/Learner.entity';
import { Organization } from 'entities/Organization.entity';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { TaskSort } from 'enums/TaskSort.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { ActivitiesQuery, TasksQuery } from 'services/API/Task/TaskApi';
import { UserActivitiesQuery } from 'services/API/UserActivities/UserActivitiesApi';

interface StaffFilters {
  search?: string;
  statuses?: EntityStatus[];
  roles?: UserRoles[];
  idIn?: string[];
}

export interface TaskFilters {
  search?: string;
  idIn?: string[];
  status?: TaskStatus;
  parentIdEq?: Task['parentId'];
  assignedToIdEq?: Task['assignedToId'];
  parentIdIsNull?: boolean;
  repertoireIdEq?: Task['repertoireId'];
  sort?: TaskSort | string[];
  assignedToEq?: Task['assignedToId'];
  organizationIdEq?: Task['organizationId'];
  createdByIdEq?: User['id'];
  sharableEq?: Task['sharable'];
  assignedToIdIsNull?: boolean;
  organizationIdIsNull?: boolean;
  typeEq?: Task['type'];
}

interface EntityFilters {
  search?: string;
  status?: EntityStatus;
  organizationIdEq?: Task['organizationId'];
  facilitatorIdsEq?: User['id'];
}

interface ScheduleFilters {
  fromTime: Date | null;
  toTime: Date | null;
  learnerId: Learner['id'];
}

const taskQueryKey = {
  tasks: ['tasks'],
  tasksList: ['tasks', 'list'],
  taskDetails: (taskId: Task['id']): string[] => [
    ...taskQueryKey.tasks,
    'details',
    taskId
  ],
  taskActivityGroup: (taskId: Task['id']): string[] => [
    ...taskQueryKey.tasks,
    'activityGroup',
    taskId
  ],
  filteredTaskActivity: (
    filters: ActivitiesQuery
  ): (string | { filters: ActivitiesQuery })[] => [
    ...taskQueryKey.tasks,
    'activity',
    { filters }
  ],
  filteredTasks: (
    filters: TaskFilters
  ): (string | { filters: TaskFilters })[] => [
    ...taskQueryKey.tasksList,
    { filters }
  ],
  taskCount: (filters: TaskFilters): (string | { filters: TaskFilters })[] => [
    ...taskQueryKey.tasksList,
    'count',
    { filters }
  ],
  taskCreator: (taskId: Task['id']): string[] => [
    ...taskQueryKey.taskDetails(taskId),
    'createdBy'
  ],
  taskAssignee: (taskId: Task['id']): string[] => [
    ...taskQueryKey.taskDetails(taskId),
    'assignedTo'
  ],
  taskSteps: (taskId: Task['id']): string[] => [
    ...taskQueryKey.taskDetails(taskId),
    'steps'
  ]
};

const sharedOrgTaskQueryKey = {
  sharedOrgTasksList: ['sharedOrgTasksList'],
  filteredSharedOrgTasksList: (
    filters: TaskFilters
  ): (string | { filters: TaskFilters })[] => [
    ...sharedOrgTaskQueryKey.sharedOrgTasksList,
    { filters }
  ]
};

const folderQueryKey = {
  folders: ['folders'],
  filteredFolders: (
    filters: TasksQuery
  ): (string | { filters: TasksQuery })[] => [
    ...folderQueryKey.folders,
    { filters }
  ]
};

const userQueryKey = {
  users: ['users'],
  usersList: ['users', 'list'],
  userActivityGroup: (userId: User['id']): string[] => [
    ...userQueryKey.users,
    'activityGroup',
    userId
  ],
  filteredUserActivity: (
    filters: UserActivitiesQuery
  ): (string | { filters: UserActivitiesQuery })[] => [
    ...userQueryKey.users,
    'activity',
    { filters }
  ],
  userDetails: (userName: User['username']): string[] => [
    ...userQueryKey.users,
    'details',
    userName
  ],
  user: (id: User['id']): string[] => [...userQueryKey.users, 'user', id],
  staff: (id: Organization['id']): string[] => [
    ...userQueryKey.usersList,
    'staff',
    id
  ],
  learners: (id: Organization['id']): string[] => [
    ...userQueryKey.usersList,
    'learners',
    id
  ],
  filteredStaff: (
    id: Organization['id'],
    filters: StaffFilters
  ): (string | { filters: StaffFilters })[] => [
    ...userQueryKey.staff(id),
    { filters }
  ],
  filteredLearners: (
    id: Organization['id'],
    filters: EntityFilters
  ): (string | { filters: EntityFilters })[] => [
    ...userQueryKey.learners(id),
    { filters }
  ]
};

const scheduleQueryKey = {
  schedulesList: ['schedules', 'list'],
  userSchedulesList: (learnerId: Learner['id']): string[] => [
    ...scheduleQueryKey.schedulesList,
    learnerId
  ],
  filteredSchedules: (
    filters: ScheduleFilters
  ): (string | { filters: ScheduleFilters })[] => [
    ...scheduleQueryKey.userSchedulesList(filters.learnerId),
    { filters }
  ]
};

const learnerReportQueryKey = {
  learnerReport: ['learner', 'report'],
  reportTasks: (
    username: User['username'],
    filters: TaskFilters
  ): (string | { filters: TaskFilters })[] => [
    ...learnerReportQueryKey.learnerReport,
    username,
    'tasks',
    { filters }
  ]
};

const assessmentQueryKey = {
  assessmentsList: ['assessments', 'list'],
  associationsList: ['associations', 'list'],
  organizationAssessments: (id: Organization['id']): string[] => [
    ...assessmentQueryKey.assessmentsList,
    id
  ],
  taskAssessments: (id: Task['id']): string[] => [
    ...assessmentQueryKey.assessmentsList,
    id
  ],
  userAssessments: (id: Organization['id'], userId: User['id']): string[] => [
    ...assessmentQueryKey.organizationAssessments(id),
    userId
  ],
  organizationAssociations: (id: Organization['id']): string[] => [
    ...assessmentQueryKey.associationsList,
    id
  ],
  userAssociations: (id: Organization['id'], userId: User['id']): string[] => [
    ...assessmentQueryKey.organizationAssociations(id),
    userId
  ]
};

const organizationQueryKey = {
  organizations: ['organizations'],
  organizationsList: ['organizations', 'list'],
  filteredOrganizations: (
    filters: EntityFilters
  ): (string | { filters: EntityFilters })[] => [
    ...organizationQueryKey.organizationsList,
    { filters }
  ],
  organization: (slugId: Organization['slugId']): string[] => [
    ...organizationQueryKey.organizations,
    'details',
    slugId
  ],
  organizationDetails: (slugId: Organization['slugId']): string[] => [
    ...organizationQueryKey.organization(slugId),
    'info'
  ],
  organizationReport: (slugId: Organization['slugId']): string[] => [
    ...organizationQueryKey.organizations,
    'report',
    slugId
  ],
  organizationReportOverview: (slugId: Organization['slugId']): string[] => [
    ...organizationQueryKey.organizationReport(slugId),
    'overview'
  ],
  organizationReportStaff: (slugId: Organization['slugId']): string[] => [
    ...organizationQueryKey.organizationReport(slugId),
    'staff'
  ],
  organizationReportLearners: (slugId: Organization['slugId']): string[] => [
    ...organizationQueryKey.organizationReport(slugId),
    'learners'
  ],
  organizationAdmin: (
    slugId: Organization['slugId'],
    adminId: User['id']
  ): string[] => [
    ...organizationQueryKey.organization(slugId),
    'admin',
    adminId
  ]
};

const taskAssetHistoryQueryKey = {
  taskAssetHistory: ['assetHistory'],
  taskAssetHistoryDetails: (taskId: Task['id']): string[] => [
    ...taskAssetHistoryQueryKey.taskAssetHistory,
    'details',
    taskId
  ]
};

export const queryKeys = {
  ...taskQueryKey,
  ...sharedOrgTaskQueryKey,
  ...folderQueryKey,
  ...userQueryKey,
  ...scheduleQueryKey,
  ...learnerReportQueryKey,
  ...assessmentQueryKey,
  ...organizationQueryKey,
  ...taskAssetHistoryQueryKey,
  authenticatedUserData: ['authUserData'],
  repertoires: ['repertoires']
};
