export enum ApiMatchErrors {
  'user.emailNotFound' = "There doesn't seem to be an account with that email. Please enter a valid email, or if you need more help reach out to our customer team",
  'user.isDeactivated' = 'It looks like you are trying to log in to an inactive account. Please reach out to us so that we can help you get set back up',
  'user.isInactive' = 'It looks like you are trying to log in to an inactive account. Please reach out to us so that we can help you get set back up',
  'user.oldPasswordIsNotValid' = 'Old password is not valid',
  'user.usernameIsNotUnique' = 'Username is already taken',
  'user.phoneNumberIsNotValid' = 'Phone number is not valid',
  'user.cannotDeactivateYourself' = 'Sorry, you can not deactivate yourself. Deselect yourself and try again.',
  'emailIsNotUnique' = 'Email is already taken',
  'organization.admin.emailIsNotUnique' = 'Email is already taken',
  'taskSchedule.scheduledTimeConflict' = "It's not allowed to schedule the same task twice to the same time and date",
  'auth.userCredentialsAreNotValid' = 'The email, username or password you entered is invalid',
  'validationFailed' = 'Sorry, your input fields are not valid',
  'requestNotFound' = 'This password link is no longer valid.',
  'requestIsExpired' = 'This password link is no longer valid.',
  'requestAlreadyUsed' = 'This password link is no longer valid.',
  'requestIsNotActive' = 'This password link is no longer valid.',
  'task.notFound' = 'Task not found',
  'organization.outOfLimits' = "You don't have enough licenses to create another user. Please reach out to avail team to add more licenses.",
  'user.organization.isDeactivated' = 'It looks like you are trying to log in to an account with an inactive organization. Please reach out to us so that we can help you get set back up.',
  'file.videoDurationLimit' = 'The duration of the video should not be more than 5 mins'
}
