import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { updateUserData } from 'actions/User/userActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { queryClient } from 'index';
import {
  EditPersonalInformationForm,
  FormData
} from 'views/People/EditPersonalInformationForm';

interface Props {
  data: User;
  isOpen: boolean;
  onCloseModal: () => void;
}

export const EditPersonalInformationModal: FC<Props> = ({
  data,
  isOpen,
  onCloseModal
}) => {
  const { id, avatarUrl, isLearner, username } = data;

  const { slugId } = useParams<{ slugId: string }>();
  const { data: organizationData } = useOrganizationBySlug();

  const { isLoading, isError, isSuccess, error, mutateAsync } = useMutation<
    User,
    ApiError,
    FormData
  >(
    (formData: FormData) =>
      updateUserData(id, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        role: formData.role,
        email: formData.email,
        username: formData.username,
        status: formData.status,
        phoneNumber: formData.phoneNumber || null,
        avatar: formData.image !== avatarUrl ? formData.image : undefined
      }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(queryKeys.userDetails(username), data);
        queryClient.invalidateQueries(queryKeys.organizationDetails(slugId));
        queryClient.invalidateQueries(
          isLearner
            ? queryKeys.learners(organizationData!.id)
            : queryKeys.staff(organizationData!.id)
        );
        onCloseModal();
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage="Personal information successfully updated"
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <Modal
        isOpen={isOpen}
        onCloseModal={onCloseModal}
        title="Edit personal information"
      >
        <EditPersonalInformationForm
          data={data}
          isLoading={isLoading}
          onSubmit={mutateAsync}
          onCloseModal={onCloseModal}
        />
      </Modal>
    </>
  );
};
