import { ActivityGroup } from 'entities/ActivityGroup.entity';
import { ApiError } from 'entities/ApiError.entity';
import { Assessment } from 'entities/Assessment.entity';
import { ScheduleDTO } from 'entities/DTO/ScheduleDTO.entity';
import { StepDTO } from 'entities/DTO/StepDTO.entity';
import { TaskDTO } from 'entities/DTO/TaskDTO.entity';
import { Schedule } from 'entities/Schedule.entity';
import { Step } from 'entities/Step.entity';
import { Task } from 'entities/Task.entity';
import { TaskActivity } from 'entities/TaskActivity.entity';
import { TaskAssetGroup } from 'entities/TaskAssetGroup.entity';
import { User } from 'entities/User.entity';
import {
  ActivitiesGroupQuery,
  ActivitiesQuery,
  AssessmentsQuery,
  DuplicateTaskToOrganizations,
  SchedulesQuery,
  TaskApi,
  TaskAssetHistoryGroup,
  TaskDuplicateOptions,
  TasksQuery
} from 'services/API/Task/TaskApi';

export const fetchTasks = async (query: TasksQuery): Promise<Task[]> => {
  try {
    const { data } = await TaskApi.fetchTasks(query);

    return Task.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchTaskDetails = async (taskId: Task['id']): Promise<Task> => {
  try {
    const response = await TaskApi.fetchTaskDetails(taskId);

    return Task.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateTaskDetails = async (
  taskId: Task['id'],
  taskUpdateDTO: Partial<TaskDTO>
): Promise<Task> => {
  try {
    const response = await TaskApi.updateTaskDetails(taskId, taskUpdateDTO);

    return Task.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const createTask = async (taskCreateDTO: TaskDTO): Promise<Task> => {
  try {
    const response = await TaskApi.createTask(taskCreateDTO);

    return Task.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const deleteTasks = async (
  taskIds: Array<Task['id']>
): Promise<void> => {
  try {
    await TaskApi.deleteTasks(taskIds);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchAssessments = async (
  query: AssessmentsQuery
): Promise<Assessment[]> => {
  try {
    const { data } = await TaskApi.fetchAssessments(query);

    return Assessment.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const duplicateTask = async (
  taskId: Task['id'],
  taskOptions: TaskDuplicateOptions
): Promise<Task> => {
  try {
    const { data } = await TaskApi.duplicateTask(taskId, taskOptions);

    return Task.deserialize(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const duplicateTasks = async (
  taskIds: string[],
  taskOptions: TaskDuplicateOptions
): Promise<Task[]> => {
  try {
    const { data } = await TaskApi.duplicateTasks(taskIds, taskOptions);

    return Task.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const duplicateTaskToOrganizations = async (
  taskIds: string[],
  taskOptions: DuplicateTaskToOrganizations
): Promise<void> => {
  try {
    await TaskApi.duplicateTaskToOrganizations(taskIds, taskOptions);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchTaskStep = async (
  taskId: Task['id'],
  stepId: Step['id']
): Promise<Step> => {
  try {
    const { data } = await TaskApi.fetchTaskStep(taskId, stepId);

    return Step.deserialize(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchTaskSteps = async (taskId: Task['id']): Promise<Step[]> => {
  try {
    const { data } = await TaskApi.fetchTaskSteps(taskId);

    return Step.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateTaskStep = async (
  taskId: Task['id'],
  stepId: Step['id'],
  taskStepUpdateDTO: Partial<StepDTO>
): Promise<Step> => {
  try {
    const response = await TaskApi.updateTaskStep(
      taskId,
      stepId,
      taskStepUpdateDTO
    );

    return Step.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const createTaskStep = async (
  taskId: Task['id'],
  taskStepCreateDTO: StepDTO
): Promise<Step> => {
  try {
    const response = await TaskApi.createTaskStep(taskId, taskStepCreateDTO);

    return Step.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const moveTaskStep = async (
  taskId: Task['id'],
  stepId: Step['id'],
  index: number
): Promise<Step[]> => {
  try {
    const response = await TaskApi.moveTaskStep(taskId, stepId, index);

    return Step.deserializeAsList(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const moveTask = async (
  taskId: Task['id'],
  index: number,
  parentId: string | null
): Promise<Task> => {
  try {
    const response = await TaskApi.moveTask(taskId, index, parentId);

    return Task.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchTaskSchedules = async (
  query: SchedulesQuery
): Promise<Schedule[]> => {
  try {
    const { data } = await TaskApi.fetchTaskSchedules(query);

    return Schedule.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateTaskSchedule = async (
  scheduleId: Schedule['id'],
  scheduleUpdateDTO: Partial<ScheduleDTO>
): Promise<Schedule> => {
  try {
    const response = await TaskApi.updateTaskSchedule(
      scheduleId,
      scheduleUpdateDTO
    );

    return Schedule.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const createTaskSchedule = async (
  scheduleCreateDTO: ScheduleDTO
): Promise<Schedule> => {
  try {
    const response = await TaskApi.createTaskSchedule(scheduleCreateDTO);

    return Schedule.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const deleteTaskSchedule = async (
  scheduleId: Schedule['id']
): Promise<void> => {
  try {
    await TaskApi.deleteTaskSchedule(scheduleId);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const deleteRepeatedTaskSchedule = async (
  scheduleId: Schedule['id'],
  stoppedAt: Date
): Promise<Schedule> => {
  try {
    const response = await TaskApi.deleteRepeatedTaskSchedule(
      scheduleId,
      stoppedAt
    );

    return Schedule.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchTaskActivities = async (
  query: ActivitiesQuery
): Promise<TaskActivity[]> => {
  try {
    const { data } = await TaskApi.fetchTaskActivities(query);

    return TaskActivity.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchTaskActivitiesGroup = async (
  query: ActivitiesGroupQuery
): Promise<ActivityGroup[]> => {
  try {
    const { data } = await TaskApi.fetchTaskActivitiesGroup(query);

    return ActivityGroup.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchTaskCount = async (
  query: Omit<TasksQuery, 'sort'>
): Promise<number> => {
  try {
    const { data } = await TaskApi.fetchTaskCount(query);

    return data;
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchTaskAssetHistoryGroup = async (
  query: TaskAssetHistoryGroup
): Promise<TaskAssetGroup[]> => {
  try {
    const { data } = await TaskApi.fetchTaskAssetHistoryGroup(query);

    return TaskAssetGroup.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchUserTasksForReport = async (
  learnerId: User['id']
): Promise<Task[]> => {
  try {
    const { data } = await TaskApi.fetchUserTasksForReport(learnerId);

    return Task.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};
