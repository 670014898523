import React, { FC, useCallback, useMemo, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { CreateTaskFab } from 'components/CreateTaskFab';
import { DataTable } from 'components/DataTable';
import { GetItemSizeCallback } from 'components/DataTable/DataTable';
import { OnMoveActionProps, SubRows } from 'components/DataTable/Row';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { RowMoveOptions } from 'enums/RowMoveOptions.enum';
import { TableDnDType } from 'enums/TableDnDType.enum';
import { useLearnerTasks } from 'hooks/Task/useLearnerTasks';
import { useInitialLoading } from 'hooks/useInitialLoading';
import { queryClient } from 'index';
import {
  changeOrderInFolder,
  changeOrderInList,
  dragItemFromFolder,
  dragItemToFolder
} from 'utils/helpers/taskDragEndActions';
import { CreateFolderModal } from 'views/Library/CreateFolderModal';
import { CreateTaskModal } from 'views/Task/CreateTaskModal';

import { columns } from './columns';
import { LearnerTaskLibrarySubRows } from './LearnerTaskLibrarySubRows';

import styles from './LearnerTaskLibrary.module.scss';

interface Props {
  user: User;
}

const ROW_PREFIX_LENGTH = 4;

export const LearnerTaskLibrary: FC<Props> = ({ user }) => {
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);
  const [isDragError, setIsDragError] = useState(false);

  const onToggleCreateFolderModal = useCallback(() => {
    setIsCreateFolderModalOpen(
      (prevIsCreateFolderModalOpen) => !prevIsCreateFolderModalOpen
    );
  }, []);

  const onToggleCreateTaskModal = useCallback(() => {
    setIsCreateTaskModalOpen(
      (prevIsCreateTaskModalOpen) => !prevIsCreateTaskModalOpen
    );
  }, []);

  const filters = useMemo(
    () => ({
      sort: ['learnerLibraryIndex:ASC'],
      assignedToEq: user.id,
      organizationIdEq: user.organizationId,
      parentIdIsNull: true,
      parentIdEq: undefined
    }),
    [user.id, user.organizationId]
  );

  const { data, hasNextPage, fetchNextPage, isFetching, isError } =
    useLearnerTasks({ user, filters });

  const updateLearnerTaskData = useCallback(
    (newRecords) => {
      queryClient.setQueryData(queryKeys.filteredTasks(filters), {
        pages: [newRecords]
      });
    },
    [filters]
  );

  const isInitialLoading = useInitialLoading(isFetching);

  const getRowSize: GetItemSizeCallback = useCallback(
    ({ expandedRows, rows, ROW_HEIGHT }) =>
      (index: number): number => {
        if (!expandedRows.includes(rows[index]?.id)) {
          return ROW_HEIGHT;
        }

        const task = rows[index].original as Task | undefined;
        const count = task?.tasksAmount || 0;

        return count === 0 ? ROW_HEIGHT : count * ROW_HEIGHT + ROW_HEIGHT;
      },
    []
  );

  const onDragEnd = useCallback(
    ({ records, setRecords, updateListItemSize }) =>
      (result: DropResult) => {
        const {
          destination,
          source,
          draggableId: draggableItemId,
          combine,
          type
        } = result;

        const isDraggableFolder = draggableItemId.startsWith('row');
        const isFolderSource = source.droppableId.startsWith('row');
        const isFolderDestination = destination?.droppableId.startsWith('row');
        const isFolderCombine = combine?.draggableId.startsWith('row');

        const draggableId = isDraggableFolder
          ? draggableItemId.substr(ROW_PREFIX_LENGTH)
          : draggableItemId;

        const defaultDragOptions = {
          draggableId,
          records,
          setRecords,
          updateLearnerTaskData
        };

        if (
          source.index === destination?.index &&
          source.droppableId === destination.droppableId
        ) {
          return;
        }

        // When change the order in the list
        if (
          !combine &&
          destination &&
          !isFolderSource &&
          !isFolderDestination
        ) {
          changeOrderInList({
            ...defaultDragOptions,
            sourceIndex: source.index,
            destinationIndex: destination.index
          }).catch(() => {
            setIsDragError(true);
            setRecords(records);
          });
        }

        // When change the order in the folder
        if (
          !combine &&
          destination?.droppableId === source.droppableId &&
          type.startsWith(TableDnDType.Folder)
        ) {
          changeOrderInFolder({
            ...defaultDragOptions,
            source,
            destinationIndex: destination.index
          }).catch(() => {
            setIsDragError(true);
            setRecords(records);
          });

          return;
        }

        // When drag an item to the folder
        if (
          type === TableDnDType.Default &&
          isFolderCombine &&
          !isDraggableFolder
        ) {
          dragItemToFolder({
            ...defaultDragOptions,
            combine
          }).catch(() => {
            setIsDragError(true);
            setRecords(records);
          });
        }

        // When drag an item from the folder
        if (
          type.startsWith(TableDnDType.Folder) &&
          !combine &&
          !destination &&
          isFolderSource
        ) {
          dragItemFromFolder({
            ...defaultDragOptions,
            source
          })?.catch(() => {
            setIsDragError(true);
            setRecords(records);
          });

          return;
        }

        updateListItemSize();
      },
    [updateLearnerTaskData]
  );

  const onMoveRow = useCallback(
    ({ records, setRecords }) =>
      ({ rowId, index, parentId, action }: OnMoveActionProps) => {
        const newUpIndex = index ? index - 1 : 0;
        const newDownIndex = index >= records.length ? index : index + 1;

        const moveOptions = {
          draggableId: rowId,
          records,
          setRecords,
          updateLearnerTaskData,
          destinationIndex:
            action === RowMoveOptions.Up ? newUpIndex : newDownIndex
        };

        // When change the order in the folder
        if (parentId) {
          changeOrderInFolder({
            ...moveOptions,
            source: { droppableId: parentId, index }
          }).catch(() => {
            setIsDragError(true);
            setRecords(records);
          });
        }

        // When change the order in the list
        if (!parentId) {
          changeOrderInList({
            ...moveOptions,
            sourceIndex: index
          }).catch(() => {
            setIsDragError(true);
            setRecords(records);
          });
        }
      },
    [updateLearnerTaskData]
  );

  const renderSubRows = useCallback(
    ({ row, onMove }: SubRows) => (
      <LearnerTaskLibrarySubRows row={row} onMove={onMove} />
    ),
    []
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <StatusSnackBar
        isError={isDragError}
        errorMessage={ErrorMessages.FailedPostRequest}
        onClose={() => {
          setIsDragError(false);
        }}
      />
      <div className={styles.container}>
        <LoadingOverlay loading={isInitialLoading}>
          <If condition={!isInitialLoading}>
            <DataTable
              isLoading={isFetching}
              hasNextPage={hasNextPage}
              onLoadMore={fetchNextPage}
              data={data?.pages.flat() || []}
              columns={columns}
              renderSubRows={renderSubRows}
              getRowSize={getRowSize}
              onMoveRow={onMoveRow}
              onCustomDragEnd={onDragEnd}
              isCombineEnabled
              emptyMessage={EmptyStateMessages.TaskPage}
            />
          </If>

          <CreateTaskFab
            onCreateTask={onToggleCreateTaskModal}
            onCreateFolder={onToggleCreateFolderModal}
          />
        </LoadingOverlay>
      </div>

      <CreateFolderModal
        options={{
          assignedToEq: user.id,
          organizationIdEq: user.organizationId
        }}
        onCloseModal={onToggleCreateFolderModal}
        isOpen={isCreateFolderModalOpen}
      />

      <CreateTaskModal
        options={{
          assignedToEq: user.id,
          organizationIdEq: user.organizationId
        }}
        onCloseModal={onToggleCreateTaskModal}
        isOpen={isCreateTaskModalOpen}
      />
    </>
  );
};
