import { format } from 'date-fns';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { UserRoleLabels, UserRoles } from 'enums/UserRoles.enum';
import { date, deserialize, object, serializable } from 'serializr';
import { UserResponse } from 'services/API/User/UserApi';

import { File } from './File.entity';
import { Organization } from './Organization.entity';

export class User {
  @serializable
  id = '';

  @serializable
  firstName = '';

  @serializable
  lastName = '';

  @serializable
  email = '';

  @serializable
  role: UserRoles = UserRoles.Learner;

  @serializable
  phoneNumber? = '';

  @serializable
  username = '';

  @serializable(object(Organization))
  organization?: Organization | null;

  @serializable(object(File))
  avatar?: File | null;

  @serializable
  status: EntityStatus = EntityStatus.Active;

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  lastSessionDate: Date | null = null;

  @serializable
  organizationId: string | null = null;

  @serializable
  receiveEmailNotifications? = true;

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get avatarUrl(): string {
    return this.avatar?.url || '';
  }

  get initials(): string {
    const matches = this.fullName.match(/\b(\w)/g);

    return matches ? matches.join('') : '';
  }

  get formattedCreatedAt(): string {
    return this.createdAt ? format(this.createdAt, 'd MMMM, yyyy') : '-';
  }

  get formattedLastActive(): string {
    return this.lastSessionDate
      ? format(this.lastSessionDate, 'dd MMMM yyyy')
      : '-';
  }

  get isLearner(): boolean {
    return this.role === UserRoles.Learner;
  }

  get formattedRole(): string {
    return UserRoleLabels[this.role];
  }

  get hasAdminRights(): boolean {
    return this.role === UserRoles.SuperAdmin || this.role === UserRoles.Admin;
  }

  static deserialize(json: Object | string): User {
    return deserialize(User, json);
  }

  static deserializeAsList(list: User[] | UserResponse[]): User[] {
    return list.map(User.deserialize);
  }
}
