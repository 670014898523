import { format } from 'date-fns';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { date, deserialize, object, serializable } from 'serializr';
// eslint-disable-next-line import/no-cycle
import { OrganizationResponse } from 'services/API/Organization/OrganizationApi';
import slugify from 'slugify';

import { File } from './File.entity';

export class Organization {
  @serializable
  id = '';

  @serializable
  slugId = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt: Date = new Date();

  @serializable
  name = '';

  @serializable
  address = '';

  @serializable
  type = '';

  @serializable
  description? = '';

  @serializable(object(File))
  image?: File | null;

  @serializable
  managersAmount = 0;

  @serializable
  managersAmountLimit = 0;

  @serializable
  learnersAmount = 0;

  @serializable
  learnersAmountLimit = 0;

  @serializable
  facilitatorsAmount = 0;

  @serializable
  facilitatorsAmountLimit = 0;

  @serializable
  passiveFacilitatorsAmount = 0;

  @serializable
  passiveFacilitatorsAmountLimit = 0;

  @serializable
  totalUsersAmountLimit = 0;

  @serializable
  totalUsersAmount = 0;

  @serializable
  status: EntityStatus = EntityStatus.Active;

  @serializable(date())
  lastUserSessionDate: Date = new Date();

  @serializable
  adminId = '';

  get imageUrl(): string {
    return this.image?.url || '';
  }

  get slug(): string {
    return slugify(this.name, { lower: true });
  }

  get formattedLicenses(): string {
    return `${this.learnersAmount} / ${this.learnersAmountLimit}`;
  }

  get formattedCreatedAt(): string {
    return this.createdAt ? format(this.createdAt, 'd MMMM, yyyy') : '-';
  }

  get formattedLastActive(): string {
    return this.lastUserSessionDate
      ? format(this.lastUserSessionDate, 'dd MMMM yyyy')
      : '-';
  }

  static deserialize(json: Object | string): Organization {
    return deserialize(Organization, json);
  }

  static deserializeAsList(
    list: Organization[] | OrganizationResponse[]
  ): Organization[] {
    return list.map(Organization.deserialize);
  }
}
