import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField } from '@mui/material';
import { FileUploader } from 'components/FileUploader';
import { PasswordAdornment } from 'components/PasswordAdornment';
import { PasswordWarningBanner } from 'components/PasswordWarningBanner';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { isPasswordValid, isUsernameValid } from 'utils/helpers/validators';

import styles from './CreateLearnerForm.module.scss';

export interface FormData {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  image?: string | null;
}

interface Props {
  isLoading: boolean;
  onCloseModal: () => void;
  onSubmit: (formData: FormData) => void;
}

export const CreateLearnerForm: FC<Props> = ({
  onSubmit,
  isLoading,
  onCloseModal
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>('');

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  const validatePassword = (password: string): string => {
    let errorMessage = '';

    if (!password) {
      errorMessage = ErrorMessages.FailedRequiredField;
    } else if (!isPasswordValid(password)) {
      errorMessage = 'Password is not valid';
    }

    setPasswordError(errorMessage);

    return errorMessage;
  };

  return (
    <form
      className={styles['create-learner-form']}
      onSubmit={handleSubmit((formData) => onSubmit(formData))}
    >
      <Controller
        control={control}
        name="image"
        render={({ field: { onChange } }) => {
          return (
            <FileUploader label="Profile picture" onChangeFile={onChange} />
          );
        }}
      />
      <Controller
        rules={{ required: true }}
        name="firstName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="First name"
            margin="normal"
            inputRef={field.ref}
            error={!!errors?.firstName}
            helperText={errors?.firstName && ErrorMessages.FailedRequiredField}
            id="outlined-first-name-input"
          />
        )}
      />
      <Controller
        rules={{ required: true }}
        name="lastName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="Last name"
            margin="normal"
            error={!!errors?.lastName}
            aria-invalid={!!errors?.lastName}
            helperText={errors?.lastName && ErrorMessages.FailedRequiredField}
            id="outlined-last-name-input"
          />
        )}
      />
      <Controller
        rules={{
          validate: (value) => {
            if (!value) {
              return ErrorMessages.FailedRequiredField;
            }
            if (!isUsernameValid(value)) {
              return ErrorMessages.NotValidUsername;
            }

            return true;
          }
        }}
        name="username"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="Username"
            margin="normal"
            error={!!errors?.username}
            aria-invalid={!!errors?.username}
            helperText={errors?.username?.message}
            id="outlined-username-input"
          />
        )}
      />
      <PasswordWarningBanner className={styles.banner} />
      <Controller
        rules={{
          validate: (value) => !validatePassword(value)
        }}
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            label="Password"
            autoComplete="new-password"
            margin="normal"
            inputRef={field.ref}
            value={field.value}
            onChange={field.onChange}
            error={!!errors?.password}
            helperText={errors?.password && passwordError}
            id="outlined-password-input"
            InputProps={{
              endAdornment: (
                <PasswordAdornment
                  isPasswordVisible={showPassword}
                  onShowPassword={() => setShowPassword(!showPassword)}
                />
              )
            }}
          />
        )}
      />
      <div className={styles.footer}>
        <Grid container alignItems="stretch" spacing={2}>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
