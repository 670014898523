import { format } from 'date-fns';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { date, deserialize, object, serializable } from 'serializr';
// eslint-disable-next-line import/no-cycle
import { LearnerResponse } from 'services/API/Learner/LearnerApi';

import { File } from './File.entity';

export class Learner {
  @serializable
  id = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt: Date = new Date();

  @serializable
  role = '';

  @serializable
  firstName = '';

  @serializable
  lastName = '';

  @serializable
  username = '';

  @serializable
  email = '';

  @serializable
  phoneNumber? = '';

  @serializable(object(File))
  avatar?: File | null;

  @serializable
  status = EntityStatus.Active;

  @serializable(date())
  lastSessionDate: Date = new Date();

  @serializable
  facilitatorId = '';

  get avatarUrl(): string {
    return this.avatar?.url || '';
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get formattedCreatedAt(): string {
    return this.createdAt ? format(this.createdAt, 'd MMMM, yyyy') : '-';
  }

  get formattedLastSessionDate(): string {
    return this.lastSessionDate
      ? format(this.lastSessionDate, 'd MMMM, yyyy')
      : '-';
  }

  static deserialize(json: Object | string): Learner {
    return deserialize(Learner, json);
  }

  static deserializeAsList(list: Learner[] | LearnerResponse[]): Learner[] {
    return list.map(Learner.deserialize);
  }
}
