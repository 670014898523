export enum Routes {
  Root = '/',
  Login = '/login',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  Account = '/account',
  Dashboard = '/dashboard',
  Organizations = '/organizations',
  Library = '/library',
  LibrarySettings = '/library-settings',
  TaskDetails = '/tasks/:id',
  CreateOrganization = '/organizations/create',
  OrganizationDetails = '/organizations/:slug/:slugId',
  OrganizationInformation = '/organizations/:slug/:slugId/info',
  OrganizationReport = '/organizations/:slug/:slugId/report',
  OrganizationPeople = '/organizations/:slug/:slugId/people',
  OrganizationTaskDetails = '/organizations/:slug/:slugId/library/tasks/:id',
  OrganizationStaff = '/organizations/:slug/:slugId/people/staff',
  OrganizationLearners = '/organizations/:slug/:slugId/people/learners',
  OrganizationLibrary = '/organizations/:slug/:slugId/library',
  LearnerDetails = '/organizations/:slug/:slugId/people/learners/:id',
  LearnerReport = '/organizations/:slug/:slugId/people/learners/:id/report',
  StaffDetails = '/organizations/:slug/:slugId/people/staff/:id',
  StaffTaskDetails = '/organizations/:slug/:slugId/people/staff/:userId/tasks/:id',
  LearnerTaskDetails = '/organizations/:slug/:slugId/people/learners/:userId/tasks/:id'
}

export const RoutesLabels = Object.freeze<Record<string, string>>({
  [Routes.Dashboard]: 'Dashboard',
  [Routes.Organizations]: 'Organizations',
  [Routes.Library]: 'avail® Library'
});
